import React from "react";
import classes from './csctwo.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import nodata from '../../Assets/nodata.png';
import { Link } from "react-router-dom";
import createtopic from '../../Assets/createtopic.png'
import viewassgnmnt from '../../Assets/viewassignment.png'
import createassgnmnt from '../../Assets/createassignment.png'

const CscTwo = () => {


    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>CSC 201</p>

                <div className={classes.dashBoardDiv}>

                    <div className={classes.allcrtendview}>
                    <Link to={'/upload_assignment'}><img src={createassgnmnt} className={classes.crteassgnmnt} alt="" /> </Link> 
                    <Link to={'/view_assignment'}><img src={viewassgnmnt} className={classes.crteassgnmnt} alt="" /></Link>
                    <Link to={'/make_lesson'}><img src={createtopic} className={classes.crteassgnmnt} alt="" /></Link>
                    </div>

                    <div className={classes.dgst}>
                        <p className={classes.crsetpcs}>COURSE TOPICS</p>
                        <div className={classes.imgdata}>
                            <img src={nodata} alt="" className={classes.nodata}/>
                            <div>
                                 <p className={classes.nodatatxt}>No data to show here yet</p>
                            </div>
                           
                        </div>
                        
                    </div>

                </div>

            </div>

        </div>
    );
};

export default CscTwo;
