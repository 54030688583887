import React from "react";
// import classes from '../New Assignment/NewAssignment.module.css';
import classes from '../Assignment/ViewAssignment.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function ViewAssignment() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>View Assignment Submissions</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} />
                            <p>ASSIGNMENTS</p>
                        </div>
                        <div className={classes.container}>
                            <h4 className={classes.newassign}>RECENT</h4>
                            <div className={classes.assignments}>

                                <div className={classes.assignmentcard}>
                                    <Link to={'/ViewAssignSub'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                        <Link to={'/ViewAssignSub'}><h4 className={classes.assignmentinfo1}>Write about the history of Bytes</h4></Link>
                                        <Link to={'/ViewAssignSub'}><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco </p></Link>
                                        {/* <Link to={'/ViewAssignSub'}><a href="#">View Assignment</a></Link> */}
                                        <span className={classes.date}>Uploaded on 8th April, 2024 - 09:45am</span>
                                    </div>
                                    <button><Link to={'/ViewAssignSub'} className={classes.viewsub}>View Submissions</Link></button>
                                </div>
                                {/* <div className={classes.assignmentcard}>
                                    <Link to={'/ViewAssignSub'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                    <Link to={'/ViewAssignSub'}><h4>Principles of debugging</h4></Link>
                                        <Link to={'/ViewAssignSub'}> <p>This assignment will help you understand the...</p></Link>
                                        <Link to={'/ViewAssignSub'}><a href="#">View Assignment</a></Link>
                                        <span className={classes.date}>Uploaded on 8th April, 2024 - 09:45am</span>
                                    </div>
                                </div> */}
                            </div>

                            <h4 className={classes.olderassign}>OLDER SUBMISSIONS</h4>
                            <div className={classes.assignments}>
                                <div className={classes.assignmentcard}>
                                    <Link to={'/ViewAssignSub'}><img src={book} alt="Icon" className={classes.icon} /></Link>
                                    <div className={classes.assignmentinfo}>
                                        <Link to={'/ViewAssignSub'}><h4 className={classes.assignmentinfo1}>Summary of how computer chips are made</h4></Link>
                                        <Link to={'/ViewAssignSub'}><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco </p></Link>
                                        {/* <Link to={'/ViewAssignSub'}><a href="#">View Assignment</a></Link> */}
                                        <span className={classes.date}>Uploaded on 8th April, 2024 - 09:45am</span>
                                    </div>
                                    <button><Link to={'/ViewAssignSub'} className={classes.viewsub}>View Submissions</Link></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default ViewAssignment;
