import React from "react";
import classes from '../MycourseA/mycourseThnew.module.css'
// import classes from '../Mycourse1/mycourse12.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.png'
import upload from '../../Assets/uploadphoto.svg'
import { Link } from "react-router-dom";
import firsticon from '../../Assets/first1.png'
import secondicon from '../../Assets/second1.png'
import thirdicon from '../../Assets/third1.png'

function CourseA() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.wholePage}>
                            <div className={classes.topDivs}> 
                                <div className={classes.first}>
                                    <img className={classes.bookimg} src={firsticon} />

                                    <div className={classes.thetxt}>
                                        <p className={classes.head}>Upload <br />Assignment</p>
                                        <p className={classes.paragraph}>Give your students assignments from here</p>
                                        <Link to={'/upload_assignment'}> <button className={classes.btncourse} type="button">Create</button>
                                        </Link>
                                    </div>

                                </div>
                                <div className={classes.second}>
                                    <img className={classes.bookimg} src={secondicon} />
                                    <div className={classes.thetxt}>
                                        <p className={classes.heads}>View <br />Assignment<br /> Submissions</p>
                                        <Link to={'/view_assignment'}>
                                        <button type="button" className={classes.vbutton}>View</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className={classes.third}>
                                    <img className={classes.bookimg} src={thirdicon} />
                                    <div className={classes.thetxt}>
                                        <p className={classes.head}>Create <br />Course Topic</p>
                                        <p className={classes.paragraph}>Create new course topic here</p>
                                        <Link to={'/make_lesson'}>
                                        <button className={classes.btncourse} type="button">Create</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4>
                                    COURSE TOPICS
                                </h4>

                            </div>
                            <div className={classes.courses111}>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 1</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span> </pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 2</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 3</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 4</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                            
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 5</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 6</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 7</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am <span className={classes.text}>       View</span></pre>

                                </div>
                                <div className={classes.topics}>
                                    <p className={classes.coursetopic}>COURSE TOPIC 8</p>
                                    <p className={classes.kilobyte}>How Kilobytes and Megabytes work</p>
                                    <p className={classes.french}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt ut labore et dolore
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                    <pre className={classes.date}>Created on 17th April 2024 - 09:30am<span className={classes.text}>       View</span></pre>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CourseA;
