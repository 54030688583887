import React from "react";
import classes from './MakeLesson.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import boldIcon from '../../Assets/boldIcon.png';
import italicIcon from '../../Assets/italicIcon.png';
import underlineIcon from '../../Assets/underlineIcon.png';
import imgIcon from '../../Assets/imgIcon.png';
import linkIcon from '../../Assets/linkIcon.png';
import back from '../../Assets/backicon.svg'
import { Link } from "react-router-dom";

function ViewAssignSub() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} />
                            <p>CREATE COURSE TOPIC</p>
                        </div>
                        <div className={classes.topContnt}>

                            <div className={classes.formgroup}>
                                <p>Type your lesson note for each topic here</p>
                                <label for="title">Title of Lesson</label> <br />
                                <input type="text" id="title" className={classes.forminput} placeholder="Enter Title of lesson" />
                            </div>
                            <p className={classes.captionfor}>Description</p>
                            <div className={classes.abcs}>
                                <table className={classes.inputTable}>
                                    <thead>
                                        <tr>
                                            <th colSpan={1}>
                                                <select>
                                                    <option>Heading 1</option>
                                                    <option>Heading 2</option>
                                                    <option>Heading 3</option>
                                                </select>
                                            </th>
                                            <th>
                                                <div className={classes.icons}>
                                                    <div className={classes.strLineHeader} />
                                                    <img src={boldIcon} className={classes.boldIcon} alt="Bold" />
                                                    <img src={italicIcon} className={classes.boldIcon} alt="Italic" />
                                                    <img src={underlineIcon} className={classes.boldIcon} alt="Underline" />
                                                    <img src={imgIcon} className={classes.boldIcon} alt="Upload" />
                                                    <img src={linkIcon} className={classes.boldIcon} alt="Link" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <textarea type="text" placeholder="Type your text here" className={classes.inputField} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.underButton}>
                                <Link to={'/upload_success'}>
                               ` <button className={classes.pulish}>
                                    Publish Lesson
                                </button>`
                                </Link>
                                <button className={classes.cancelBtn}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ViewAssignSub;
