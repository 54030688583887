import React from "react";
import classes from './LectureTimetableLV.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';
import timeIcon from '../../Assets/timeIcon.png';
import locationIcon from '../../Assets/location.png'
import addIcon from '../../Assets/addIcon.png'
import { Link } from "react-router-dom";
import leftIcon from '../../Assets/leftIcon.png'
import rightIcon from '../../Assets/rightIcon.png'

const ListView = () => {
  // const ButtonContainer = () => {
  //   const [showContainer, setShowContainer] = useState(false);
  

  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>My classes</p>

        <div className={classes.dashBoardDiv}>
          <ExamView />
          <div className={classes.header}>
              <div className={classes.theHeaderTxt}>
                  <p className={classes.lectureTxt}>Lecture Timetable</p>

                  <p>
                      <Link to={'/my_classes'}>
                        <div className={classes.createClass}>
                            <img src={addIcon}/>
                            Create a New Class
                        </div>
                      </Link>
                  </p>
              </div>

            <div className={classes.month}>
                <img src={leftIcon}/>
                <p>May 2024</p>
                <img src={rightIcon}/>
            </div>

          </div>
          <div className={classes.sldk}>

            <div className={classes.lvTable}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
                  <div className={classes.linkBtn}>
                    <button>Click link</button>
                  </div>
              </div>
            </div>

            <div className={classes.lvTable2}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
                  <div className={classes.linkBtn}>
                    <button >Click link</button>
                    {/* {showContainer && <div className={classes.link}>This is the container that appears on button click!</div>} */}
                  </div>
              </div>
            </div>

            <div className={classes.lvTable}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
                  <div className={classes.linkBtn}>
                    <button>Click link</button>
                  </div>
              </div>
            </div>

            <div className={classes.lvTable2}>
              <div className={classes.lvTableMain}>
                  <div className={classes.dateRow}>
                  <p>Thur<br/><span>May 4th</span></p>
                  </div>
                  <div className={classes.verLine}/>

                  <div className={classes.timeIconContainer}>
                      <div className={classes.iconTxt}>
                      <img src={timeIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  <div className={classes.iconTxt}>
                      <img src={locationIcon}/>
                      <p>8:00am - 10:00am</p>
                  </div>
                  </div>

                  <div className={classes.courseInfo}>
                      <p className={classes.hfh4}>CSC 201</p>
                      <p>Computer programming and debugging</p>
                  </div>
                  <div className={classes.linkBtn}>
                    <button>Click link</button>
                  </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
};

export default ListView;
