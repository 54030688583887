import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashbboard/Dashboard';
import DashboardNav from './Components/Navigation.js/Navigation';import logo from './logo.svg';
import './App.css';
import Profile from './Pages/Profile/Profile';
import ViewAssignment from './Pages/Assignment/ViewAssignment';
import ViewAssignSub from './Pages/ViewAssignSub/ViewAssignSub';
import AssignSub from './Pages/AssignSubView/AssignSubView';
import UploadAssignment from './Pages/UploadAssignment/UploadAssign';
import UploadAssignment2 from './Pages/UploadAssignment2/UploadAssign2';
import UploadAssignment3 from './Pages/UploadAssignment3/UploadAssign3';
import UploadAssign4 from './Pages/UploadAssignment4/UploadAssign4';
import UploadAssignment5 from './Pages/UploadAssignment5/UploadAssignment5';
import ConfirmAssign from './Pages/ConfirmAssignment/ConfirmAssign';
import DeleteAssign from './Pages/DeleteAssignment/DeleteAssign';
import UploadTest from './Pages/UploadTest/UploadTest';
import UploadTest1 from './Pages/UploadTest1/UploadTest1';
import UploadScore from './Pages/UploadScore/UploadScore';
import CourseMaterial from './Pages/UploadCourseMaterial/CourseMaterial';
import CourseUpload from './Pages/CourseUpload/CourseUpload';
import CourseMaterial1 from './Pages/UploadCourse1/CourseMaterial1';
// import Mycourse2 from './Pages/Mycourse2/mycourse2';
import Login from './Pages/Login/login';
// import Course1 from './Pages/Mycourse1/mycourse1';
import UploadExam from './Pages/UploadExam/UploadExam';
import UploadExam0 from './Pages/UploadExam0/UploadExam0';
import UploadExam2 from './Pages/UploadExam2/UploadExam2';
import LecturerTimetabble from './Pages/Lecturer Timetable/LecturerTimetabble'
import MakeLesson from './Pages/Make Lesson/MakeLesson'
import MyClasses from './Pages/My Classes/MyClasses'
import ListViewCalender from './Pages/Lecture Timetable LV/LectureTimetableLV'
import Course_Lesson from './Pages/Course_Lesson/course_lesson'
import LessonEdit from './Pages/Lesson edit/LessonEdit'
import UploadSuccess from './Pages/Upload Success/UploadSuccess'
import CourseTone from './Pages/MycourseA/mycourseThnew';
import MycourseTwo from './Pages/MycourseThenew/mycourseThenew';
import CscOne from './Pages/cscone/cscone';
import CscTwo from './Pages/csctwo/csctwo';
import AttendanceReport from './Pages/Attendance Report/AttendanceReport';
import Class_Attendance from './Pages/Class_Attendance/class_attendance';
import Student_Attendance from './Pages/Student_Attendance/student_attendance';
import ViewClass_Attendance from './Pages/ViewClass_Attendance/Viewclass_attendance';
import ViewStudent_Attendance from './Pages/ViewStudent_Attendance/ViewStudent_attendance';
import ExamTest2 from './Pages/ExamTest2/ExamTest2';
import ExamTest from './Pages/ExamTest/ExamTest';


function App() {
  return (
  
    <Routes>
      
      <Route path='/' element={<Login/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/navigation' element={<DashboardNav/>} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/view_assignment' element={<ViewAssignment/>} />
      <Route path='/ViewAssignSub' element={<ViewAssignSub/>} />
      <Route path='/AssignSubView' element={<AssignSub/>} />
      <Route path='/lecturer_timetable' element={<LecturerTimetabble/>} />
      <Route path='/attendance_report' element={<AttendanceReport/>} />
      <Route path='/make_lesson' element={<MakeLesson/>} />
      <Route path='/my_classes' element={<MyClasses/>} />
      <Route path='/lv_calender' element={<ListViewCalender/>} />
      <Route path='/lesson_edit' element={<LessonEdit/>} />
      <Route path='/upload_success' element={<UploadSuccess/>} />


      {/* <Route path='/Voucher' element={<Voucher/>} /> */}

      
      <Route path='/upload_assignment' element={<UploadAssignment />} />
      <Route path='/upload_assignment2' element={<UploadAssignment2 />} />
      <Route path='/upload_assignment3' element={<UploadAssignment3 />} />
      <Route path='/assignment4' element={<UploadAssign4 />} />
      <Route path='/edit_assignment5' element={<UploadAssignment5 />} />
      <Route path='/confrim_assignment' element={<ConfirmAssign/>} />
      <Route path='/delete_assignment' element={<DeleteAssign/>} />

      <Route path='/upload_test1' element={<UploadTest1/>} />
      <Route path='/upload_test' element={<UploadTest/>} />
      <Route path='/upload_score' element={<UploadScore/>} />

      <Route path='/course_material' element={<CourseMaterial/>} />
      <Route path='/course_upload' element={<CourseUpload/>} />
      <Route path='/course_upload1' element={<CourseMaterial1/>} />
      {/* <Route path='/mycourse2' element={<Mycourse2/>} /> */}
      <Route path='/mycourse_two' element={<MycourseTwo/>} />
      <Route path='/login' element={<Login/>} />
      {/* <Route path='/course1' element={<Course1/>} /> */}
      <Route path='/course_one' element={<CourseTone/>} />
      <Route path='/upload_exam1' element={<UploadExam/>} />
      <Route path='/upload_exam0' element={<UploadExam0/>} />
      <Route path='/upload_exam2' element={<UploadExam2/>} />
      <Route path='/course_lesson' element={<Course_Lesson/>} />
      <Route path='/cscone' element={<CscOne/>} />
      <Route path='/csctwo' element={<CscTwo/>} />
      <Route path='/class_attendance' element={<Class_Attendance/>} />
      <Route path='/View_class_attendance' element={<ViewClass_Attendance/>} />
      <Route path='/student_attendance' element={<Student_Attendance/>} />
      <Route path='/View_student_attendance' element={<ViewStudent_Attendance/>} />

      <Route path='/exam_test' element={<ExamTest/>} />
      <Route path='/exam_test2' element={<ExamTest2/>} />
      
      
    </Routes>
   
  );
}
 export default App;
