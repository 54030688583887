import React from "react";
import classes from './MyClasses.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import back from '../../Assets/backicon.svg';

function ViewAssignSub() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} alt="back icon"/>
                            <p>CLASSES</p>
                        </div>
                        <div className={classes.topContnt}>
                            <p className={classes.heas}>Create a new class</p>
                            <div className={classes.underSelection}>
                                <div>
                                    <label className={classes.labelFr} htmlFor="select">Title of Lesson</label><br/>   
                                    <select name="select" className={classes.courses}>
                                        <option>CSC 201</option>
                                    </select>
                                </div>
                                <div>
                                    <label className={classes.labelFr} htmlFor="date">Date of class</label><br/>   
                                    <input
                                        type="date"
                                        id="date"
                                        className={classes.date}
                                        defaultValue="12/05/2024"
                                    />
                                </div>
                                <div>
                                    <label className={classes.labelFr} htmlFor="start-time">Select start time</label><br/>   
                                    <select name="start-time" className={classes.courses}>
                                        <option>8:00am </option>
                                    </select>
                                </div>
                                <div>
                                    <label className={classes.labelFr} htmlFor="end-time">Select end time</label><br/>   
                                    <select name="end-time" className={classes.courses}>
                                        <option>10:00am</option>
                                    </select>
                                </div>
                                <div>
                                    <label className={classes.labelFr} htmlFor="repeat">Repeat</label><br/>   
                                    <select name="repeat" className={classes.courses}>
                                        <option>Weekly</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <button className={classes.createClass}>
                                    Create class
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ViewAssignSub;
