import React from "react";
import classes from '../ConfirmAssignment/ConfirmAssign.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import pdf from '../../Assets/pdf.svg'
import msword from '../../Assets/msword.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'


function ConfirmAssign() {
    return (
        <div>
            <div className={classes.acctnum}>
                <div className={classes.acctnumDiv}>
                    <p className={classes.acct1t}>Are you sure you want to delete this assignment.</p>
                    <Link to={'/delete_assignment'}><button className={classes.btn1tet}>Delete</button></Link>
                </div>
            </div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                     <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img className={classes.subIcon1} src={back} />
                            <p className={classes.subText1}>ASSIGNMENTS</p>
                        </div>
                        <div className={classes.assignmentdetailscontainer}>
                            <div className={classes.assignmentheader}>
                                <h4 className={classes.assignmenttitle}>Principles of debugging</h4>
                                {/* <p className={classes.assignmentsummary}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                    aliquip ex ea commodo consequat. </p> */}
                                <div className={classes.assignmentdates}>
                                    <span className={classes.uploaddate}><p className={classes.uploaddate1}>Uploaded on</p> <p className={classes.uploaddate2}>25/05/2024</p></span>
                                    <span className={classes.submitdate}><p className={classes.submitdate1}>Submit on</p> <p className={classes.submitdate2}>30/05/2024</p></span>
                                </div>
                            </div>
                            <div className={classes.assignmentdetails}>
                                <h4 className={classes.detailsTop1}> Assignment details</h4>
                                <p className={classes.detailsP1}> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                    dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                    nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
                                    vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>

                                    <p className={classes.detailsP1}> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                    dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                    nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
                                    vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>

                                    <p className={classes.detailsP1}> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                                    dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                    nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
                                    vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>

                            </div>
                            <div className={classes.topAttach}>
                                <h4 className={classes.attchText1}>ATTACHMENTS</h4>
                                <div className={classes.subAttach}>
                                    <div className={classes.Attach}>
                                        <img className={classes.attachImg1}src={pdf} />
                                        <p className={classes.attachText2}>Guidelines for pairing into groups</p>
                                    </div>
                                    <div className={classes.Attach}>
                                        <img className={classes.attachImg1} src={msword} />
                                        <p className={classes.attachText2} >Guidelines for pairing into groups</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className={classes.bbn}>
                            <Link to={'/edit_assignment5'}><button className={classes.submitassignmentbutton}>Edit Assignment</button></Link>
                            <p className={classes.bbnText}>Delete Assignment</p>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
}

export default ConfirmAssign;
