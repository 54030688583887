import React from "react";
import classes from '../UploadAssignment/UploadAssign.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import calendar from '../../Assets/calendar.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import FileUpload from '../../Components/FileUpload/fileupload'

function UploadAssignment() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img className={classes.topIcon} src={back} />
                            <p className={classes.assignment}>ASSIGNMENTS</p>
                        </div>
                        <div className={classes.assignmentcontainer}>

                            <div className={classes.Topgroup}>
                                <div className={classes.formgroup}>
                                    <label className={classes.title}for="title">Enter Title of Assignment</label> <br />
                                    <input type="text" id="title" className={classes.forminput}  />
                                </div>
                                <div className={classes.midgroup}>
                                    <label for='details'>Type the details of the Assignment here</label><br />
                                    <textarea name='details' className={classes.details} rows='10' cols='60' />
                                </div>
                                {/* <FileUpload /> */}
                                <div className={classes.formgroup}>
                                    <label className={classes.formLabel}>Upload attachments here</label>
                                    <div className={classes.uploadarea}>
                                        <span className={classes.uploadicon}>
                                            <img className={classes.uploadiconimg} src={upload} />
                                        </span>
                                        <span className={classes.uploadtext}>Upload your document here</span><br />
                                        Upload
                                        <input type="file" className={classes.uploadinput} />
                                    </div>
                                </div>
                                <div className={classes.date}>
                                    <div className={classes.sDate}>
                                        <p className={classes.dateText}>Start date</p>
                                        <input className={classes.dBtn} type='date' />
                                    </div>
                                    <div className={classes.sDate}>
                                        <p className={classes.dateText}>Submission date</p>
                                        <input className={classes.dBtn} type='date' />
                                    </div>
                                </div>
                                <Link to={'/upload_assignment2'}><button className={classes.submitbutton}>Upload Assignment</button></Link>
                            </div>

                            <div className={classes.Topprev}>
                                <h4 className={classes.prevAssign}>VIEW PREVIOUS ASSIGNMENTS</h4>
                                <div className={classes.midrev}>
                                    <div className={classes.previousassignments}>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>

                                        <ul className={classes.assignmentlist}>
                                            <div className={classes.assignmentcard}>
                                                <div className={classes.assignmentinfo}>
                                                    <h4 className={classes.assignheader}>WRITE ABOUT THE HISTORY OF BYTES</h4>
                                                    <p className={classes.assignp}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea...</p>
                                                    <div className={classes.bassign}>
                                                        <span className={classes.date}>Created on 11th April 2024</span>
                                                        <span className={classes.viewassign}>View Assignment</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default UploadAssignment;
