import React from "react";
import classes from './cscone.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';
import { Link } from "react-router-dom";
import createtopic from '../../Assets/createtopic.png'
import viewassgnmnt from '../../Assets/viewassignment.png'
import createassgnmnt from '../../Assets/createassignment.png'

const CscOne = () => {


    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>CSC 101</p>

                <div className={classes.dashBoardDiv}>

                    <div className={classes.allcrtendview}>
                    <Link to={'/upload_assignment'}><img src={createassgnmnt} className={classes.crteassgnmnt} alt="" /> </Link> 
                    <Link to={'/view_assignment'}><img src={viewassgnmnt} className={classes.crteassgnmnt} alt="" /></Link>
                    <Link to={'/make_lesson'}><img src={createtopic} className={classes.crteassgnmnt} alt="" /></Link>
                    </div>

                    <div className={classes.dgst}>
                        <p className={classes.crsetpcs}>COURSE TOPICS</p>
                        <div className={classes.entbx}>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 1</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 2</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 3</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.entbx1}>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 4</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo</p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 5</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                            <div className={classes.frstbx}>
                                <p className={classes.crsetpcsbx}>COURSE TOPIC 6</p>
                                <p className={classes.kbts}>How kilobytes and megabytes work</p>
                                <p className={classes.kbtslorem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                </p>
                                <div className={classes.dtedtls}>
                                    <p className={classes.crteddte}>Created on 17th April 2024 - 09:30am</p>
                                    <p className={classes.crteddtevw}>view</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default CscOne;
