import React from "react";
import classes from '../Course_Lesson/course_lesson.module.css';
import cl1 from '../../Assets/cl_first.svg';
import cl2 from '../../Assets/cl_second.svg'
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.png'
import upload from '../../Assets/uploadphoto.svg'
import { Link } from "react-router-dom";
import back from '../../Assets/bck1.jpg'
import backimg from '../../Assets/backimg.svg'

function course_lesson() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.appcontainer}>
                    <div className={classes.sidenav}>
                        <DashboardNav className={classes.scrollable} />
                    </div>

                    <div className={classes.maincontent}>
                        <Horheader />
                        <p className={classes.dshbrdtxt}>CSC 101</p>
                        <div className={classes.dashBoardDiv}>
                            <div className={classes.subContainer}>
                                <img className={classes.bckimg} src={backimg} />
                                <p>COURSE LESSON</p>
                            </div>
                            <div className={classes.topContnt}>
                                <div className={classes.fflex}>
                                    <p className={classes.cTopic1}>COURSE TOPIC 1</p>
                                    <button className={classes.downloadbtn} type='button'>Download as PDF</button>
                                </div>

                                <div className={classes.formgroup}>
                                    {/* <p>Type your lesson note for each topic here</p>
                                    <label for="title">Title of Lesson</label> <br /> */}
                                    <div className={classes.forminput}>How Kilobytes and Megabytes work</div>
                                </div>
                                {/* <p className={classes.captionfor}>Description</p> */}
                                <div className={classes.abcs}>
                                    <table className={classes.inputTable}>
                                        
                                        <tbody>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div type="text" placeholder="Type your text here" className={classes.inputField}>
                                                        <p className={classes.pPara}>
                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                                            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                                            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                                                            adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                                            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
                                                            voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                        </p>
                                                        <img src={cl1} />
                                                        <p className={classes.pPara}>
                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                                            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                                            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                                                            adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                                            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
                                                            voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                        </p>
                                                        <img src={cl2} />
                                                        <p className={classes.pPara}>
                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                                            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                                            consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                                                            adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                                            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea
                                                            voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                        </p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={classes.underButton}>
                                    <button className={classes.pulish}>
                                        Save changes
                                    </button>
                                    <button className={classes.cancelBtn}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    );
}

export default course_lesson;
