import React, { useState, useEffect } from 'react';
import logo from '../../Assets/FLlogo.png'
import dshbrd from '../../Assets/graph.png'
import dshbrdActive from '../../Assets/graph-active.png'
import reg from '../../Assets/receipt-2.png'
import history from '../../Assets/receipt-2.png'
import regActive from '../../Assets/receipt-2-active.png'
import historyActive from '../../Assets/receipt-2-active.png'
import bdata from '../../Assets/receipt-text.png'
import bdataActive from '../../Assets/receipt-text-active.png'
import elibry from '../../Assets/book.png'
import elibryActive from '../../Assets/book-active.png'
import wallet from '../../Assets/empty-wallet.png'
import walletActive from '../../Assets/empty-wallet-active.png'
import classAttndnce from '../../Assets/profile-2user.png'
import classAttndnceActive from '../../Assets/profile-2user-active.png'
import examNtests from '../../Assets/award.png'
import examNtestsActive from '../../Assets/award-active.png'
import payfee from '../../Assets/card-pos.png'
import payfeeActive from '../../Assets/card-pos-active.png'
import assgnmnt from '../../Assets/note-2.png'
import assgnmntActive from '../../Assets/note-2-active.png'
import clndr from '../../Assets/calendar.png'
import clndrActive from '../../Assets/calendar-active.png'
import buk2 from '../../Assets/book2.png'
import buk2Active from '../../Assets/book2-active.png'
import prntxmps from '../../Assets/receipt-item.png'
import prntxmpsActive from '../../Assets/receipt-item-active.png'
import usrnysc from '../../Assets/user-square.png'
import usrnyscActive from '../../Assets/user-square-active.png'
import login from '../../Assets/login.png'
import classes from './navigation.module.css'

import { Link, useLocation, useNavigate } from 'react-router-dom'



export default function DashboardNav() {
    const [activeItem, setActiveItem] = useState('Dashboard');
    const [isAttendanceDropdownOpen, setIsAttendanceDropdownOpen] = useState(false);
    const [isExamNdTestDropdownOpen, setIsExamNdTestDropdownOpen] = useState(false);
    const [isBursaryDropdownOpen, setIsBursaryDropdownOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Here you can add code to fetch the current URL or any other logic you want to run when the component mounts
        // For simplicity, let's assume the active item is determined based on the URL
        const pathname = window.location.pathname;
        // Logic to set the active item based on the URL
        // For example:
        if (pathname.includes('/dashboard')) {
            setActiveItem('Dashboard');
        } else if (pathname.includes('/profile')) {
            setActiveItem('Biodata');
            } else if (pathname.includes('/e-library')) {
                setActiveItem('Studentattndnce');
        }
        else if (pathname.includes('/senate')) {
            setActiveItem('SenateAffairs');
        }
        else if (pathname.includes('/cscone')) {
            setActiveItem('CSCONE');
        }
        else if (pathname.includes('/csctwo')) {
            setActiveItem('CSCTWO');
        }
        else if (pathname.includes('/school_summary')) {
            setActiveItem('Payschoolfees');
        }
        else if (pathname.includes('/view_assignment')) {
            setActiveItem('view_assignment');
        }
        else if (pathname.includes('/ViewAssignSub')) {
            setActiveItem('view_assignment');
        }
        else if (pathname.includes('/AssignSubView')) {
            setActiveItem('view_assignment');
        }
        else if (pathname.includes('/lecture_timetable ')) {
            setActiveItem('LecturesTimetable');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('ExamTimetable');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('Checkresult');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('ExamPass');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('Clearance');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('Clsattndnce');
        }
        else if (pathname.includes('/transactionHry')) {
            setActiveItem('History');
        }
        else if (pathname.includes('/upload_test1')) {
            setActiveItem('UploadTest');
        }
        else if (pathname.includes('/upload_assignment')) {
            setActiveItem('UploadAssignments');
        }
        else if (pathname.includes('/course_material')) {
            setActiveItem('UploadCoursematerials');
        }
        else if (pathname.includes('/exam_pass')) {
            setActiveItem('ExamPass');
        }
        else if (pathname.includes('/lecturer_timetable')) {
            setActiveItem('CourseMaterials');
        }
        else if (pathname.includes('/upload_exam0')) {
            setActiveItem('UploadExamscores');
        }
        else if (pathname.includes('/attendance_report')) {
            setActiveItem('attndncereport');
        }
        else if (pathname.includes('/exam_test')) {
            setActiveItem('ExamNdTest');}
        else if (pathname.includes('/class_attendance')) {
            setActiveItem('Class_Attendance');
        }
        else if (pathname.includes('/student_attendance')) {
            setActiveItem('Student_Attendance');
        }
    }, [location.pathname]);

    const handleItemClick = (itemName, isDropdownItem = false, dropdownName = '') => {
        setActiveItem(itemName);
        if (isDropdownItem) {
            if (dropdownName === 'Attendance') {
                setIsAttendanceDropdownOpen(true);
            } else if (dropdownName === 'ExamNdTest') {
                setIsExamNdTestDropdownOpen(true);
            // } else if (dropdownName === 'Bursary') {
            //     setIsBursaryDropdownOpen(true);
            }
        } else {
            setIsAttendanceDropdownOpen(false);
            setIsExamNdTestDropdownOpen(false);
            // setIsBursaryDropdownOpen(false);
        }
    };


    const getIcon = (itemName, defaultIcon, activeIcon) => {
        return activeItem === itemName ? activeIcon : defaultIcon;
    };

    const toggleDropdown = (dropdown) => {
        if (dropdown === 'Attendance') {
            setIsAttendanceDropdownOpen(!isAttendanceDropdownOpen);
        } else if (dropdown === 'ExamNdTest') {
            setIsExamNdTestDropdownOpen(!isExamNdTestDropdownOpen);
        // } else if (dropdown === 'Bursary') {
        //     setIsBursaryDropdownOpen(!isBursaryDropdownOpen);
        }
    };


    return (

        <div>
            <div className={classes.navbrd}>
                <div className={classes.navdshbrd}>
                    <div className={classes.logodiv}>
                        <img src={logo} className={classes.logo} />
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>GENERAL</p>
                        <Link
                            to={'/dashboard'}
                            className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Dashboard' ? classes.active : ''}`} onClick={() => handleItemClick('Dashboard')}>
                            <img src={getIcon('Dashboard', dshbrd, dshbrdActive)}
                                className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Dashboard</p>

                        </Link>
                        <Link
                            to={'/profile'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Biodata' ? classes.active : ''}`} onClick={() => handleItemClick('Biodata')}>
                            <img src={getIcon('Biodata', bdata, bdataActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Biodata</p>
                        </Link>
                        <Link
                            to={'#'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'SenateAffairs' ? classes.active : ''}`} onClick={() => handleItemClick('SenateAffairs')}>
                            <img src={getIcon('SenateAffairs', reg, regActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Senate Affairs</p>
                        </Link>
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>MY COURSES</p>
                        <Link
                            to={'/cscone'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'CSCONE' ? classes.active : ''}`} onClick={() => handleItemClick('CSCONE')}>
                            <img src={getIcon('CSCONE', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>CSC 101</p>
                        </Link>
                        <Link
                            to={'/csctwo'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'CSCTWO' ? classes.active : ''}`} onClick={() => handleItemClick('CSCTWO')}>
                            <img src={getIcon('CSCTWO', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>CSC 201</p>
                        </Link>
                        <Link
                            to={'/lecturer_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'CourseMaterials' ? classes.active : ''}`} onClick={() => handleItemClick('CourseMaterials')}>
                            <img src={getIcon('CourseMaterials', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>My Classes</p>
                        </Link>
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>UPLOADS</p>
                        <Link
                            to={'/upload_test1'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'UploadTest' ? classes.active : ''}`} onClick={() => handleItemClick('UploadTest')}>
                            <img src={getIcon('UploadTest', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Upload Test scores</p>
                        </Link>
                        <Link
                            to={'/upload_exam0'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'UploadExamscores' ? classes.active : ''}`} onClick={() => handleItemClick('UploadExamscores')}>
                            <img src={getIcon('UploadExamscores', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Upload Exam scores</p>
                        </Link>
                        <Link
                            to={'/upload_assignment'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'UploadAssignments' ? classes.active : ''}`} onClick={() => handleItemClick('UploadAssignments')}>
                            <img src={getIcon('UploadAssignments', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Upload Assignments</p>
                        </Link>
                        <Link
                            to={'/course_material'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'UploadCoursematerials' ? classes.active : ''}`} onClick={() => handleItemClick('UploadCoursematerials')}>
                            <img src={getIcon('UploadCoursematerials', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Upload Course materials</p>
                        </Link>
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>REPORTS</p>
                        <div className={classes.dropdown} onClick={() => toggleDropdown('Attendance')}>
                            <div className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Attendance' ? classes.active : ''}`} >
                                <img src={getIcon('Attendance', classAttndnce, classAttndnceActive)} className={classes.icondshbrd} alt="attendance Icon" />
                                <p className={classes.dshbrdtxt}>Class Attendance</p>
                            </div>
                            {isAttendanceDropdownOpen && (
                                <div className={classes.dropdownContent}>

                                    <Link
                                        to={'/attendance_report'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'attndncereport' ? classes.active : ''}`} onClick={() => handleItemClick('attndncereport')}>
                                        <img src={getIcon('attndncereport', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Attendance Report</p>
                                    </Link>
                                    <Link
                                        to={'/class_attendance'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Clsattndnce' ? classes.active : 'Class_Attendance'}`} onClick={() => handleItemClick('Clsattndnce')}>
                                        <img src={getIcon('Clsattndnce', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Class Attendance</p>
                                    </Link>
                                    <Link
                                        to={'/student_attendance'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Studentattndnce' ? classes.active : 'Student_Attendance'}`} onClick={() => handleItemClick('Studentattndnce')}>
                                        <img src={getIcon('Studentattndnce', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Student Attendance</p>
                                    </Link>
                                </div>
                            )}
                        </div>


                        <Link
                            to={'/exam_test'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ExamNdTest' ? classes.active : ''}`} onClick={() => handleItemClick('ExamNdTest')}>
                            <img src={getIcon('ExamNdTest', examNtests, examNtestsActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Exam and Tests</p>
                        </Link>



                    </div>

                    <div className={classes.gnrlcls}>
                        <div className={classes.thetxtlgot}>
                            <Link
                                to={'/login'} className={classes.logoutDis}>
                                <div className={classes.lgtupd}>
                                    <img src={login} className={classes.icondshbrd} />
                                    <p className={classes.dshbrdtxt}>Logout</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

