import React from "react";
import classes from '../ViewAssignSub/ViewAssignSub.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import fall from '../../Assets/clas.png';
import arleft from '../../Assets/arleft.svg'
import { Link } from "react-router-dom";

function ViewAssignSub() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>                        
                    <div className={classes.dashBoardDiv}>
                        <Link to={'/view_assignment'} className={classes.link11}>
                            <div className={classes.artop}>
                                    <div className={classes.arleft}>
                                        <img src={arleft} className={classes.arrow} />
                                    </div>
                                    <div className={classes.arright}>
                                        <p>ASSIGNMENTS</p>
                                    </div>
                            </div>
                        </Link>
                        <div className={classes.toptable}>
                            <div className={classes.lefttable}>
                                <p>View Assignment Submissions</p>                        
                            </div>
                            <div className={classes.righttable}>
                                <p>Download all Submissions</p>                        
                            </div>
                        </div>
                        <div className={classes.tableContainer}>
                            <table className={classes.stability1111}>
                                <tr>
                                    <th>S/N</th>
                                    <th>NAME OF STUDENT</th>
                                    <th>MATRIC NO.</th>
                                    <th>DATE/TIME OF SUBMISSION</th>
                                    <th>ACTION</th>   
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>April 15, 2024 - 11:43am</td>
                                    <td className={classes.buttonsept}>                                    
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton}>
                                            Download
                                            </button>
                                        </Link>
                                        <Link to={'/AssignSubView'}>
                                            <button className={classes.printReButton2}>
                                            View
                                            </button>
                                        </Link>
                                    </td>    
                                </tr>
                            </table>
                        </div>
                        <div className={classes.toptable1}>
                            <div className={classes.lefttable1}>
                                <Link to={'/AssignSubView'}>
                                    <button className={classes.printReButton}>
                                    Previous
                                    </button>
                                </Link>
                                <p> Page <span>1</span> of <span>15</span></p>
                                <Link to={'/AssignSubView'}>
                                    <button className={classes.printReButton2}>
                                    Next
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ViewAssignSub;
