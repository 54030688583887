import React from "react";
import classes from '../MycourseThenew/mycourseThenew.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.svg'
import upload from '../../Assets/uploadphoto.svg'
import theicon from '../../Assets/theicon.svg'
import { Link } from "react-router-dom";
import firsticon from '../../Assets/first1.png'
import secondicon from '../../Assets/second1.png'
import thirdicon from '../../Assets/third1.png'

function MycourseTwo() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 201</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                    <div className={classes.wholePage}>
                            <div className={classes.topDivs}>
                            <div className={classes.first}>
                                    <img className={classes.bookimg} src={firsticon} />

                                    <div className={classes.thetxt}>
                                        <p className={classes.head}>Upload <br />Assignment</p>
                                        <p className={classes.paragraph}>Give your students assignments from here</p>
                                        <Link to={'/upload_assignment'}> <button className={classes.btncourse} type="button">Create</button>
                                        </Link>
                                    </div>

                                </div>
                                <div className={classes.second}>
                                    <img className={classes.bookimg} src={secondicon} />
                                    <div className={classes.thetxt}>
                                        <p className={classes.heads}>View <br />Assignment<br /> Submissions</p>
                                        <Link to={'/view_assignment'}>
                                        <button type="button" className={classes.vbutton}>View</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className={classes.third}>
                                    <img className={classes.bookimg} src={thirdicon} />
                                    <div className={classes.thetxt}>
                                        <p className={classes.head}>Create <br />Course Topic</p>
                                        <p className={classes.paragraph}>Create new course topic here</p>
                                        <Link to={'/make_lesson'}>
                                        <button className={classes.btncourse} type="button">Create</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h4>
                                    COURSE TOPICS
                                </h4>

                            </div>
                            <div className={classes.lastone}>
                                <img className={classes.icon} src={theicon} />
                                <p className={classes.data}>No data to show here</p>
                            </div>
                            </div>
                    </div>
                        
                        

                        
                </div>
                </div>
               
            </div>
    );
}

export default MycourseTwo;
