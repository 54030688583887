import React from "react";
import classes from '../UploadExam0/UploadExam0.module.css';
import arrow_img from '../../Assets/icons8-expand-arrow-24.png'
// import logo from "../../assets/FUNA1AB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import calendar from '../../Assets/calendar.svg'

import { Link, useLocation, useNavigate } from 'react-router-dom'


function UploadExam0() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Upload Exam Scores</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        
                    <div className={classes.container}>
                            {/* <h2>Select Course and Department to upload Test Scores</h2> */}
                            <div className={classes.toptable}>
                                <p>Select Course and Department to upload Exam Scores</p>
                            </div>
                            <div className={classes.subTop}>
                                <div className={classes.theselect}>
                                    <label for='course'>Select course</label><br />
                                    
                                    <select className={classes.tSelect} name='course' id='course'>
                                        <option value='csc201'>
                                            CSC 201
                                        </option>
                                    </select>
                                </div>
                                <div className={classes.theselect}>
                                    <label for='faculty'>Select Faculty</label><br />
                                    
                                    <select className={classes.tSelect} name='faculty' id='faculty'>
                                        <option  value='science'>
                                            Science
                                        </option>
                                    </select>
                                </div>
                                <div className={classes.theselect}>
                                    <label for='department'>Select Department</label><br />
                                    
                                    <select className={classes.tSelect} name='department' id='department'>
                                        <option className={classes.cmp} value='department'>
                                        Computer Science
                                        </option>
                                    </select>
                                </div>
                                <div>
                                <Link to={'/upload_exam1'}><button className={classes.testBtn}> Generate Scoresheet</button></Link>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
};

export default UploadExam0;
