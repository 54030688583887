import React from "react";
import classes from './ExamTest2.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import boldIcon from '../../Assets/boldIcon.png';
import italicIcon from '../../Assets/italicIcon.png';
import underlineIcon from '../../Assets/underlineIcon.png';
import imgIcon from '../../Assets/imgIcon.png';
import linkIcon from '../../Assets/linkIcon.png';
import back from '../../Assets/backicon.svg'
import { Link } from "react-router-dom";

function ExamTest2() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Exams and Tests</p>
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.topContnt}>
                            <div className={classes.viewstudbtn}>
                                <p className={classes.heas}>View Exam and Test scores</p> 
                                <button type="button" className={classes.clearD}>Clear Data </button>
                            </div>
                            
                        </div>
                        
                        <div className={classes.container}>

                            <div>
                                <p className={classes.showingresult}>Showing result for MCB 101 for  2024/2025 for Microbiology department</p>
                            </div>
                            <div className={classes.thetopoftable}>
                                <p className={classes.topoftable}>2024/2025 SESSION</p>
                                <p className={classes.topoftable}>MCB 103</p>
                                <p className={classes.topoftable}>Microbiology department</p>

                            </div>
                            
                            <table className={classes.scoreTable}>
                                <thead className={classes.thScore}>
                                    <tr className={classes.tScore}>
                                        <th className={classes.sScore}>S/N</th>
                                        <th className={classes.sScore}>MATRIC NO</th>
                                        <th className={classes.sScore}>STUDENT NAME</th>
                                        <th className={classes.sScore}>SEX</th>
                                        <th className={classes.sScore}>TEST</th>
                                        <th className={classes.sScore}>EXAM</th>
                                        <th className={classes.sScore}>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody className={classes.tbScore}>
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>1</td>
                                        <td className={classes.bScore}>MCB00003346 </td>
                                        <td className={classes.bScore1}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>13</td>
                                        <td className={classes.bScore}>40</td>
                                        <td className={classes.bScore}>500</td>
                                    </tr>
                                    
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>2</td>
                                        <td className={classes.bScore}>MCB00008745 </td>
                                        <td className={classes.bScore1}>Arinola Modupeoluwa  </td>
                                        <td className={classes.bScore}>M</td>
                                        <td className={classes.bScore}>12</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>245</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>BIO00002456</td>
                                        <td className={classes.bScore1}>Badejo Mustapha  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>60</td>
                                        <td className={classes.bScore}>550</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>4</td>
                                        <td className={classes.bScore}>BIO00007450</td>
                                        <td className={classes.bScore1}>Bridget Marcel </td>
                                        <td className={classes.bScore}>M</td>
                                        <td className={classes.bScore}>11</td>
                                        <td className={classes.bScore}>40</td>
                                        <td className={classes.bScore}>768</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>5</td>
                                        <td className={classes.bScore}>BIO00006512</td>
                                        <td className={classes.bScore1}>Caroline Allyson  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>19</td>
                                        <td className={classes.bScore}>55</td>
                                        <td className={classes.bScore}>568</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>6</td>
                                        <td className={classes.bScore}>MCB00008745</td>
                                        <td className={classes.bScore1}>Daniel Wellington  </td>
                                        <td className={classes.bScore}>M</td>
                                        <td className={classes.bScore}>16</td>
                                        <td className={classes.bScore}>70</td>
                                        <td className={classes.bScore}>992</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className={classes.prntresbtn}type="button">Print Result</button>
                            
                    </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ExamTest2;
