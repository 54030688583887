import React from 'react';
// import DashboardNav from '../Components/Navigation.js/Navigation'
// import Horheader from '../Components/horheader/horheader'
import classes from './ViewStudent_attendance.module.css'
// import pysf from '../Assets/payurfees.png'
// import hostlfees from '../Assets/hostlfees.png'
// import fundurwlt from '../Assets/fundurwlt.png'
// import chekurrslt from '../Assets/chkurwlt.png'
// import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.png'
import upload from '../../Assets/uploadphoto.svg'
import { Link } from "react-router-dom"; 



function ViewStudent_Attendance() {

    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Class Attendance</p>
                    
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.topContnt}>
                            <p className={classes.heas}>Student Attendance</p>
                            <div className={classes.btnFlex}>
                                <div className={classes.txtDiv}>
                                    <p>Showing result for Olaide Monica Alade</p>
                                <p>Matric Number: MCB00004354</p>
                                <p>Level: 300</p>
                                </div>
                                <button>Clear Data</button>
                            </div>
                            <br />
                            <div className={classes.tablecont}>
                                <table className={classes.myTable}>
                                    <thead className={classes.tableHead}>
                                        <tr className={classes.Ttablehead}>
                                            <th>S/N</th>
                                            <th className={classes.nameRow}>DATE OF CLASS</th>
                                            <th className={classes.courseHead}>COURSE</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody className={classes.theTableBody}>
                                        <tr className={classes.theTableRow}>
                                            <td>1</td>
                                            <td className={classes.nameRow}>01/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>2</td>
                                            <td className={classes.nameRow}>08/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>3</td>
                                            <td className={classes.nameRow}>15/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>      
                                            <td><p className={classes.Nstatus}> Absent</p> </td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>4</td>
                                            <td className={classes.nameRow}>22/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>5</td>
                                            <td className={classes.nameRow}>29/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.Nstatus}>Absent</p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>6</td>
                                            <td className={classes.nameRow}>4/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>7</td>
                                            <td className={classes.nameRow}>11/03/2024</td>
                                            <td className={classes.courseTable}>MCB 103</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div>
                                <Link to={'/view_course_scores2'}><button className={classes.createClass}>
                                    Print Class Attendance
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ViewStudent_Attendance;