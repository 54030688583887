import React from "react";
import classes from './AttendanceReport.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';
import { Link } from "react-router-dom";
import line from '../../Assets/reportlinemsre.png'
import graph from '../../Assets/reportgraph.png'
import chart from '../../Assets/reportchrt.png'

const AttendanceReport = () => {


    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Class Attendance</p>
                <div className={classes.ourcont}>
                    <div className={classes.dashBoardDiv}>
                        {/* <ExamView /> */}
                        <p className={classes.headtxt}>Attendance Report</p>
                        <div className={classes.subTop}>
                            <div className={classes.SelectDsign}>
                                <label className={classes.courseLabel} for='course'>Select</label><br />
                                <select className={classes.courseSelect} name='course' id='course'>
                                    <option value='csc201'>
                                        Entire semester
                                    </option>
                                </select>
                            </div>
                            <div className={classes.prsntabssign}>
                                <div className={classes.prsnt}>
                                    <div className={classes.clrsymbol}></div>
                                    <p className={classes.courseLabel}>Present for class</p>
                                </div>

                                <div className={classes.prsnt}>
                                    <div className={classes.clrsymbol1}></div>
                                    <p className={classes.courseLabel}>Absent for class</p>
                                </div>
                            </div>

                        </div>


                        <div className={classes.graphreport}>
                            <img src={graph} className={classes.noScale} />
                        </div>

                    </div>
                    <div className={classes.dashBoardDiv1}>
                        <div className={classes.dashBoardDivsub}>
                            <div >
                                <p className={classes.chrttxt}>Class Attendance rate</p>
                                <img src={chart} className={`${classes.chartrprt} ${classes.noScale}`} />
                            </div>
                        </div>
                        <div className={classes.dashBoardDivsub2} >
                            <div >
                                <p className={classes.chrttxt}>Classes for the semester</p>
                                <img src={line} className={`${classes.linerprt} ${classes.noScale}`} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AttendanceReport;
