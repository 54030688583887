import React, { useState, useEffect } from 'react';
// import logo from '../../Assets/dashboardlogo.png'
import bell from '../../Assets/notification.png'
import avatar from '../../Assets/avatar.png'
// import dshbrd from '../../Assets/graph.png'
// import reg from '../../Assets/receipt-2.png'
// import bdata from '../../Assets/receipt-text.png'
// import elibry from '../../Assets/book.png'
import classes from './horheader.module.css'


export default function Horheader() {

    return (

        <div>
            <div className={classes.navbrd}>
               
                <div className={classes.horinfodashbrd}>
                    <div className={classes.usrcnr}>
                        <div className={classes.usrinfo}>
                            <img src={avatar} className={classes.avtr} />
                            <div className={classes.usrnminf}>
                                <p className={classes.usrnme}>Michael Awolowo</p>
                                <p className={classes.usrpstn}>Welcome</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.usrcnr}>
                        <img src={bell} className={classes.bel} />
                        <div className={classes.vrtlne}></div>
                        <div className={classes.stinfo}>
                            <p className={classes.stinfosts}>Associate Lecturer</p>
                            <p className={classes.stinfolvl}>Lecturer Status</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}