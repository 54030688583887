import React from 'react';
// import DashboardNav from '../Components/Navigation.js/Navigation'
// import Horheader from '../Components/horheader/horheader'
import classes from './student_attendance.module.css'
// import pysf from '../Assets/payurfees.png'
// import hostlfees from '../Assets/hostlfees.png'
// import fundurwlt from '../Assets/fundurwlt.png'
// import chekurrslt from '../Assets/chkurwlt.png'
// import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.png'
import upload from '../../Assets/uploadphoto.svg'
import { Link } from "react-router-dom";



function Student_Attendance() {

    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Class Attendance</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.topContnt}>
                            <p className={classes.heas}>Student Attendance</p>
                            <p>Select student attendance to view</p>
                            <div className={classes.underSelection}>
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Enter Matric Number</label>
                                    <select name="select" className={classes.courses}>
                                        <option>MCB00003467</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Faculty</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Biosciences</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>

                                    <label className={classes.labelFr} htmlFor="select">Select Department</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Microbiology</option>
                                    </select>
                                </div>

                                {/* </div>
    <div className={classes.underSelection6}> */}

                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="start-time">Select Session</label>
                                    <select name="start-time" className={classes.courses}>
                                        <option> 2024/2025 </option>
                                    </select>
                                </div>

                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Course</label>
                                    <select name="select" className={classes.courses}>
                                        <option>MCB 103</option>
                                    </select>
                                </div>
                                
                            </div>
                            <div>
                                <Link to={'/View_student_attendance'}><button className={classes.createClass}>
                                    View Student Attendance
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Student_Attendance;