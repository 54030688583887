import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './ExamVIew.module.css';

const ButtonToggle = () => {
  const [selectedView, setSelectedView] = useState('calendar');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/lecturer_timetable') {
      setSelectedView('calendar');
    } else if (location.pathname === '/lv_calender') {
      setSelectedView('list');
    }
  }, [location]);

  const handleClick = (view, route) => {
    setSelectedView(view);
    navigate(route);
  };

  return (
    <div>
      <p className={classes.head}>Exam Timetable for 200 level Computer Science</p>
      <div className={classes.buttonToggle}>
        <button
          className={`${classes.toggleButton} ${selectedView === 'calendar' ? classes.selected : ''}`}
          onClick={() => handleClick('calendar', '/lecturer_timetable')}
        >
          Calendar View
        </button>
        <button
          className={`${classes.toggleButton} ${selectedView === 'list' ? classes.selected : ''}`}
          onClick={() => handleClick('list', '/lv_calender')}
        >
          List View
        </button>
      </div>
    </div>
  );
};

export default ButtonToggle;
