import React from "react";
import classes from '../Profile/Profile.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.svg'
import upload from '../../Assets/uploadphoto.svg'

function Profile() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Biodata</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.headerText}>
                            <h4 className={classes.myProfile}>My Profile</h4>
                        </div>
                        <div className={classes.usrinfo}>
                            <div className={classes.userinfoSub}>
                                <img src={avatar} className={classes.avt} />
                                <div className={classes.usrnminf}>
                                    <p className={classes.usrnme}>Michael Awolowo</p>
                                    <p className={classes.usrpstn}>Associate Lecturer</p>
                                </div>
                            </div>
                            <div className={classes.uploadBtn}>
                                <button> <img src={upload} /> Upload Photo</button>
                                <input type='file' className={classes.uploadFile} />
                            </div>
                            
                        </div>
                        <div className={classes.studHeader}>
                            <h4 className={classes.studentInfo}> Student Information</h4>
                        </div>


                        <div className={classes.studInfo}>
                            <div className={classes.studText}>
                                <div className={classes.textInfo}>
                                    <p classname={classes.textTitle}>Name</p>
                                    <p className={classes.textName}>Michael Awolowo</p>
                                </div>
                                <div className={classes.textInfo} >
                                    <p classname={classes.textTitle}>Email address</p>
                                    <p  className={classes.textName}>AAndersen@gmail.com</p>
                                </div>
                                <div className={classes.textInfo}>
                                    <p  classname={classes.textTitle}>Phone number</p>
                                    <p  className={classes.textName}>0901 009 2370</p>
                                </div>
                                <div className={classes.textInfo}>
                                    <p  classname={classes.textTitle}>Courses taken</p>
                                    <p  className={classes.textName}>CSC 101<br />CSC 201</p>
                                </div>
                            </div>
                            <div className={classes.studText2}>
                                <div className={classes.textInfo}>
                                    <p  classname={classes.textTitle}>Sex</p>
                                    <p  className={classes.textName}>Male</p>
                                </div>
                                <div className={classes.textInfo}>
                                    <p  classname={classes.textTitle}>Faculty</p>
                                    <p  className={classes.textName}>School of Science</p>
                                </div>
                                <div  className={classes.textInfo}>
                                    <p  classname={classes.textTitle}>Department</p>
                                    <p  className={classes.textName}>Computer Science</p>
                                </div>
                                <div  className={classes.textInfo}>
                                    
                                </div>
                            </div>
                            
                        </div>

                        <div className={classes.addrHeader}>
                            <h4 className={classes.headerText}>Address Information</h4>
                        </div>
                        <div className={classes.addrInfo}>
                            <div className={classes.addrText}>
                                <div className={classes.infoAddres}>
                                    <p className={classes.infoText}  >Residential Address</p>
                                    <p className={classes.infoValue}>No 11, Adeoluwa Close,
                                        Bodja, Ibadan, Oyo state</p>
                                </div>
                                <div className={classes.infoAddres}>
                                    <p  className={classes.infoText}  >State of Origin</p>
                                    <p  className={classes.infoValue}>Ogun State</p>
                                </div>
                                <div className={classes.infoAddres}>
                                    <p  className={classes.infoText}  >LGA</p>
                                    <p  className={classes.infoValue}>Abeokuta South</p>
                                </div>
                                <div className={classes.infoAddres}>
                                    <p  className={classes.infoText}  >State of Residence</p>
                                    <p  className={classes.infoValue}>Oyo State</p>
                                </div>
                            </div>
                               
                            
                        </div>
                        </div>

                        
                </div>
                </div>
               
            </div>
    );
}

export default Profile;
