import React from "react";
import classes from '../UploadAssignment5/UploadAssignment5.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import calendar from '../../Assets/calendar.svg'
import tree from '../../Assets/tree.svg'
import pdf from '../../Assets/pdf.svg'
import msword from '../../Assets/msword.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function UploadAssignment5() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img className={classes.assignImg5} src={back} />
                            <p className={classes.assignText5}> EDIT ASSIGNMENT</p>
                        </div>
                        <div className={classes.assignmentcontainer}>

                            <div className={classes.Topgroup}>
                                <div className={classes.formgroup}>
                                    <label className={classes.textTitle} for="title">Enter Title of Assignment</label> <br />
                                    <input type="text" id="title" value='Principles of debugging' className={classes.forminput}  />
                                </div>
                                <div className={classes.midgroup}>
                                    <label for='details'>Type the details of the Assignment here</label><br />
                                    <textarea name='details' className={classes.details} rows='10' cols='101' value='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae 
dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut 
Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae 
dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut 
' />
                                </div>
                                <div className={classes.edit}>
                                    <div className={classes.formgroup}>
                                        <label className={classes.uloadat}>Upload attachments here</label>
                                        <div className={classes.uploadarea}>
                                            <span className={classes.uploadicon}>
                                                <img src={upload} />
                                            </span>
                                            <span className={classes.uploadtext}>Upload your document here</span><br />
                                            Upload
                                            <input type="file" className={classes.uploadinput} />
                                        </div>
                                    </div>
                                    <div className={classes.Attach}>
                                        <img className={classes.attachimg5} src={pdf} />
                                        <span className={classes.attachspan5}>Guidelines for pairing into groups</span><br />
                                        <p className={classes.pAttach}>Delete</p>
                                    </div>
                                    <div className={classes.Attach}>
                                        <img className={classes.attachimg5} src={msword} />
                                        <span className={classes.attachspan5}>Guidelines for pairing into groups</span><br />
                                        <p className={classes.pAttach}>Delete</p>
                                    </div>
                                </div>

                                <div className={classes.date}>
                                    <div className={classes.sDate}>
                                        <p className={classes.dateText}>Start date</p>
                                        <input className={classes.dBtn} type='date' />
                                    </div>
                                    <div className={classes.sDate}>
                                        <p className={classes.dateText}>Submission date</p>
                                        <input className={classes.dBtn} type='date' />
                                    </div>
                                </div>
                                <Link to={'/confrim_assignment'}><button className={classes.submitbutton}>Upload Assignment</button></Link>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};

export default UploadAssignment5;
