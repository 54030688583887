import React from 'react';
import DashboardNav from '../Components/Navigation.js/Navigation'
import Horheader from '../Components/horheader/horheader'
import classes from './Dashboard.module.css'
import pysf from '../Assets/payurfees.png'
import hostlfees from '../Assets/hostlfees.png'
import fundurwlt from '../Assets/fundurwlt.png'
import chekurrslt from '../Assets/chkurwlt.png'
import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
import rgstrurcurses from '../Assets/rgstrurcurses.png'
import icon111 from '../Assets/icon111.svg'
import icon222 from '../Assets/icon2222.svg'
import icon333 from '../Assets/icon333.svg'
import clk1 from '../Assets/clk.svg'
import clk2 from '../Assets/clk2.svg'
import date from '../Assets/date.svg'
import date2 from '../Assets/date2.svg'
import loct from '../Assets/loct.svg'


const Dashboard = () => {

    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Dashboard</p>
                <div className={classes.qlnks}>

                    <div className={classes.frstcolumn}>
                        <img src={icon111} className={classes.icon111} />
                        <h2>06/24</h2>
                        <p>Total number of classes this semester</p>
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={icon222} className={classes.icon222} />
                        <h2>120</h2>
                        <p>Total number of students enrolled in your courses</p>
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={icon333} className={classes.icon333} />
                        <h2>02</h2>
                        <p>Total number of courses for this semester</p>
                    </div>
                </div>
                <div className={classes.qlnks1}>


                </div>
                <div>

                    <div className={classes.qlnks}>
                        <div>
                            <p className={classes.qlnktxt}>UPCOMING CLASS</p>
                        <div className={classes.frstcolumn1}>
                            <h2>CSC 201</h2>
                            <p>Computer programming and debugging</p>
                            <div className={classes.intxt}>
                                <div className={classes.intxt1}>
                                    <img src={date} className={classes.date} />
                                </div>
                                <div className={classes.intxt2}>
                                    <p>Monday, 4th June 2024</p>
                                </div>
                            </div>
                            <div className={classes.intxtt}>
                                <div className={classes.intxt1}>
                                    <img src={clk1} className={classes.clk1} />
                                </div>
                                <div className={classes.intxt2}>
                                    <p>8:00am - 10:00am</p>
                                </div>
                            </div>
                            <button>
                                Join Class
                            </button>
                        </div>
                        </div>
                        
                        {/* <div className={classes.dshlstb1}>
                            <p className={classes.qlnktxt1}>UPCOMING EVENTS</p>
                            <div className={classes.dshlstb}>
                                <h2>25th Inaugural lecture on Mathematics</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                <div className={classes.lttext}>
                                    <div className={classes.intxtt1}>
                                        <div className={classes.intxt1}>
                                            <img src={date2} className={classes.clk2} />
                                        </div>
                                        <div className={classes.intxt2}>
                                            <p>12th May, 2024</p>
                                        </div>
                                    </div>
                                    <div className={classes.intxtt1}>
                                        <div className={classes.intxt1}>
                                            <img src={clk2} className={classes.clk2} />
                                        </div>
                                        <div className={classes.intxt2}>
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.intxtt12}>
                                    <div className={classes.intxt1}>
                                        <img src={loct} className={classes.clk2} />
                                    </div>
                                    <div className={classes.intxt2}>
                                        <p>Professor Anifowose lecture theatre</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                           
                            <div className={classes.dshlstb}>
                                <h2>Field Trip to NIITDA Laboratory</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                <div className={classes.lttext}>
                                    <div className={classes.intxtt1}>
                                        <div className={classes.intxt1}>
                                            <img src={date2} className={classes.clk2} />
                                        </div>
                                        <div className={classes.intxt2}>
                                            <p>12th May, 2024</p>
                                        </div>
                                    </div>
                                    <div className={classes.intxtt1}>
                                        <div className={classes.intxt1}>
                                            <img src={clk2} className={classes.clk2} />
                                        </div>
                                        <div className={classes.intxt2}>
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.intxtt12}>
                                    <div className={classes.intxt1}>
                                        <img src={loct} className={classes.clk2} />
                                    </div>
                                    <div className={classes.intxt2}>
                                        <p>Professor Anifowose lecture theatre</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div>
                            <p className={classes.qlnktxt1}>UPCOMING EVENTS</p>
                            <div className={classes.evntkad}>
                                <div className={classes.evntkadcntnt}>
                                    <p className={classes.evntlrgtxt}>25th Inaugural lecture on Mathematics</p>
                                    <p className={classes.evntlrgtxtlil}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                </div>
                                <div className={classes.clndrcntnthead}>
                                    <div className={classes.clndrcntnt} >
                                        <img src={date2} className={classes.calendarimg} />
                                        <p className={classes.dtetxt}>12th May, 2024</p>
                                    </div>

                                    <div className={classes.clndrcntnt}>
                                        <img src={clk2} className={classes.clockimg} />
                                        <p className={classes.dtetxt}>8:00am - 11:00am</p>
                                    </div>

                                </div>
                                <div>
                                    <div className={classes.clndrcntnt}>
                                        <img src={loct} className={classes.locationimg} />
                                        <p className={classes.dtetxt}>Professor Anifowose lecture theatre</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.evntkad2}>
                                <div className={classes.evntkadcntnt}>
                                    <p className={classes.evntlrgtxt}>Field Trip to NIITDA Laboratory</p>
                                    <p className={classes.evntlrgtxtlil}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                                </div>
                                <div className={classes.clndrcntnthead}>
                                    <div className={classes.clndrcntnt} >
                                        <img src={date2} className={classes.calendarimg} />
                                        <p className={classes.dtetxt}>12th May, 2024</p>
                                    </div>

                                    <div className={classes.clndrcntnt}>
                                        <img src={clk2} className={classes.clockimg} />
                                        <p className={classes.dtetxt}>8:00am - 11:00am</p>
                                    </div>

                                </div>
                                <div>
                                    <div className={classes.clndrcntnt}>
                                        <img src={loct} className={classes.locationimg} />
                                        <p className={classes.dtetxt}>Professor Anifowose lecture theatre</p>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

    );
}

export default Dashboard;