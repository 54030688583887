import React from "react";
import classes from './LessonUpdate.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import boldIcon from '../../Assets/boldIcon.png';
import italicIcon from '../../Assets/italicIcon.png';
import underlineIcon from '../../Assets/underlineIcon.png';
import imgIcon from '../../Assets/imgIcon.png';
import linkIcon from '../../Assets/linkIcon.png';
import back from '../../Assets/backicon.svg'
import imgForNote from '../../Assets/imgForNote.png'
import imgForNote2 from '../../Assets/imgForNote2.png'

function LessonEdit() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subContainer}>
                            <img src={back} />
                            <p>CREATE COURSE TOPIC</p>
                        </div>
                        <div className={classes.topContnt}>

                            <div className={classes.formgroup}>
                                <p>Type your lesson note for each topic here</p>
                                <div className={classes.nameOfLesson}>
                                COURSE TOPIC 1
                                </div>
                                <label for="title">Title of Lesson</label> <br />
                                <div type="text" id="title" className={classes.forminput}>How Kilobytes and Megabytes work</div>
                            </div>
                            <p className={classes.captionfor}>Description</p>
                            <div className={classes.abcs}>
                                <table className={classes.inputTable}>
                                    <thead>
                                        <tr>
                                            <th colSpan={1}>
                                                <select>
                                                    <option>Heading 1</option>
                                                    <option>Heading 2</option>
                                                    <option>Heading 3</option>
                                                </select>
                                            </th>
                                            <th>
                                                <div className={classes.icons}>
                                                    <div className={classes.strLineHeader} />
                                                    <img src={boldIcon} className={classes.boldIcon} alt="Bold" />
                                                    <img src={italicIcon} className={classes.boldIcon} alt="Italic" />
                                                    <img src={underlineIcon} className={classes.boldIcon} alt="Underline" />
                                                    <img src={imgIcon} className={classes.boldIcon} alt="Upload" />
                                                    <img src={linkIcon} className={classes.boldIcon} alt="Link" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div type="text" placeholder="Type your text here" className={classes.inputField}>
                                                    <p>
                                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis 
nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea 
voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                    </p>
                                                    <img src={imgForNote}/>
                                                    <p>
                                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis 
nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea 
voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                    </p>
                                                    <img src={imgForNote2}/>
                                                    <p>
                                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia 
consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis 
nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea 
voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.underButton}>
                                <button className={classes.pulish}>
                                Save changes
                                </button>
                                <button className={classes.cancelBtn}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LessonEdit;
