import React from "react";
import classes from './LecturerTimetabble.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import ExamView from '../../Components/Exam View2/ExamView';
import { Link } from "react-router-dom";
import addIcon from '../../Assets/addIcon.png'
import leftIcon from '../../Assets/leftIcon.png'
import rightIcon from '../../Assets/rightIcon.png'

const ExamTimetable = () => {


  return (
    <div className={classes.appcontainer}>
      <div className={classes.sidenav}>
        <DashboardNav className={classes.scrollable} />
      </div>

      <div className={classes.maincontent}>
        <Horheader />
        <p className={classes.dshbrdtxt}>Exam Timetable</p>

        <div className={classes.dashBoardDiv}>
          <ExamView />

          <div className={classes.month}>
                <img src={leftIcon}/>
                <p>May 2024</p>
                <img src={rightIcon}/>
            </div>
          <div className={classes.examTbContainer}>
    <table className={classes.examTable}>
        <thead>
            <tr>
                    <th colSpan="7" className={classes.calendarCaption}>
                <div className={classes.header}>
                        <p>Full Exam Timetable<br/><span>May 4, 2024 - May 19, 2024</span></p>
                    <div className={classes.createClassPo}>
                      <Link to={'/my_classes'}>
                        <div className={classes.createClass}>
                            <img src={addIcon}/>
                            Create a New Class
                        </div>
                      </Link>
                  </div>
                </div>
                    </th>
            </tr>
            <tr>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>
                    <div className={classes.eventDayg}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>1</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>2</td>
                <td>3</td>
               
                <td>
                    <div className={classes.eventDayy}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>4</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>5</td>
                <td>6</td>

                <td>7</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDayg}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>8</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>9</td>
                <td>10</td>
                
                <td>
                    <div className={classes.eventDayy}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>11</p>
                            <p className={classes.course}>CSC 205</p>
                            <p className={classes.fooTxt1}>3:00pm - 5:00pm</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                
                <td>12</td>
                <td>13</td>
                <td>14</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDayg}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>15</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                
                <td>16</td>
                <td>17</td>
               
                <td>
                    <div className={classes.eventDayy}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>18</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDayg}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>22</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>23</td>
                <td>25</td>
                <td>
                    <div className={classes.eventDayy}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>26</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>27</td>
                <td>28</td>
            </tr>
            <tr>
            <td>
                    <div className={classes.eventDayg}>
                        {/* <div className={classes.verline}/> */}
                        <div className={classes.txtVer}>
                            <p className={classes.header1}>4</p>
                            <p className={classes.course}>CSC 201</p>
                            <p className={classes.fooTxt1}>8:00am - 10:00am</p>
                            <p className={classes.fooTxt}>Andersen Tedder hall </p>
                        </div>
                    </div>
                </td>
                <td>29</td>
                <td>31</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>

        </div>

      </div>

    </div>
  );
};

export default ExamTimetable;
