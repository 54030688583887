import React from "react";
import classes from '../UploadTest/UploadTest.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import calendar from '../../Assets/calendar.svg'

import { Link, useLocation, useNavigate } from 'react-router-dom'

function UploadTest() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Upload Test Scores</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.topDiv}>
                            <img className={classes.topimg} src={back} />
                        <p className={classes.topText}>Select Course and Department to upload Test Scores</p>
                        </div>
                        
                        <div className={classes.container}>
                            {/* <h2>Select Course and Department to upload Test Scores</h2> */}
                            <div className={classes.toptable}>
                                <p className={classes.topText1}>Enter your scores in the test score field</p>
                                <Link to={'/upload_score'}><button className={classes.uploadbtn}>Upload scores</button></Link>
                            </div>
                            
                            <table className={classes.scoreTable}>
                                <thead className={classes.thScore}>
                                    <tr className={classes.tScore}>
                                        <th className={classes.sScore}>S/N</th>
                                        <th className={classes.sScore}>Name of Student</th>
                                        <th className={classes.sScore}>Matric No</th>
                                        <th className={classes.sScore}>Test Score</th>
                                        <th className={classes.sScore}>Course Code</th>
                                    </tr>
                                </thead>
                                <tbody className={classes.tbScore}>
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>1</td>
                                        <td className={classes.bScore}>Moyosore Oluwalade Fasehun</td>
                                        <td className={classes.bScore}>CSC0468649890</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="27" /></td>
                                        <td className={classes.bScore}> CSC 201</td>
                                    </tr>
                                    
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>2</td>
                                        <td className={classes.bScore}>Babatunde Wasiu Ayelade</td>
                                        <td className={classes.bScore}>CCSC0466469961</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="11" /></td>
                                        <td className={classes.bScore}>CSC 201</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>Babatunde Wasiu Ayelade</td>
                                        <td className={classes.bScore}>CCSC0466469961</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="11" /></td>
                                        <td className={classes.bScore}>CSC 201</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>4</td>
                                        <td className={classes.bScore}>Babatunde Wasiu Ayelade</td>
                                        <td className={classes.bScore}>CCSC0466469961</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="11" /></td>
                                        <td className={classes.bScore}>CSC 201</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>5</td>
                                        <td className={classes.bScore}>Babatunde Wasiu Ayelade</td>
                                        <td className={classes.bScore}>CCSC0466469961</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="11" /></td>
                                        <td className={classes.bScore}>CSC 201</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>6</td>
                                        <td className={classes.bScore}>Babatunde Wasiu Ayelade</td>
                                        <td className={classes.bScore}>CCSC0466469961</td>
                                        <td className={classes.bScore}><input className={classes.inputScore} type="text" value="11" /></td>
                                        <td className={classes.bScore}>CSC 201</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
};

export default UploadTest;
