import React from "react";
import classes from '../AssignSubView/AssignSubView.module.css';
// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import fall from '../../Assets/clas.png';
import arleft from '../../Assets/arleft.svg'
import pdf1 from '../../Assets/pdf1.svg'
import { Link } from "react-router-dom";

function AssignSubView() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>CSC 101</p>                        
                    <div className={classes.dashBoardDiv}>
                        <Link to={'/ViewAssignSub'} className={classes.link11}>
                            <div className={classes.artop}>
                                    <div className={classes.arleft}>
                                        <img src={arleft} className={classes.arrow} />
                                    </div>
                                    <div className={classes.arright}>
                                        <p>ASSIGNMENTS</p>
                                    </div>
                            </div>
                        </Link>
                        <div className={classes.toptable}>
                            <div className={classes.lefttable}>
                                <p>Assignment Submission for Moyosore Oluwalade Fasehun</p>                        
                            </div>
                            {/* <div className={classes.righttable}>
                                <p>Download all Submissions</p>                        
                            </div> */}
                        </div>
                        <div className={classes.newdiv}> <br />
                            <span>The History of Bytes</span>
                            <div className={classes.lefttable12}>
                                <Link to={'/no_data'}>
                                <img src={pdf1} className={classes.pdf1} />
                                </Link>
                            </div>
                            <p>CSCS04689597</p> <br />
                            <p>Date and Time of Submission</p>
                            <h5>15/06/2024 - 09:12am</h5> <br /><br />
                        </div>
                        <Link to={'/no_data'}>
                            <button className={classes.printReButton22}>
                                Download Assignment
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AssignSubView;
