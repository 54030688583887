import React from 'react';
// import DashboardNav from '../Components/Navigation.js/Navigation'
// import Horheader from '../Components/horheader/horheader'
import classes from './Viewclass_attendance.module.css'
// import pysf from '../Assets/payurfees.png'
// import hostlfees from '../Assets/hostlfees.png'
// import fundurwlt from '../Assets/fundurwlt.png'
// import chekurrslt from '../Assets/chkurwlt.png'
// import gturcrsmtrls from '../Assets/gturcrsmtrls.png'
// import rgstrurcurses from '../Assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import avatar from '../../Assets/avatar.png'
import upload from '../../Assets/uploadphoto.svg'
import { Link } from "react-router-dom";



function ViewClass_Attendance() {

    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Class Attendance</p>
                    {/* <p className={classes.qlnktxt}>Quicklinks</p> */}
                    {/* <div className={classes.qlnks}>
                    
                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>

                    <div className={classes.frstcolumn}>

                        <div className={classes.frstsubcolumn}>
                            <img src={gturcrsmtrls} className={classes.gturcrsmtrls} />
                            <img src={chekurrslt} className={classes.chekurrslt} />
                        </div>
                        <img src={rgstrurcurses} className={classes.rgstrurcurses} />
                    </div>

                    <div className={classes.frstcolumn}>
                        <img src={pysf} className={classes.pysf} />
                        <div className={classes.frstsubcolumn} >
                            <img src={hostlfees} className={classes.hostlfees} />
                            <img src={fundurwlt} className={classes.fundurwlt} />
                        </div>

                    </div>
                </div> */}
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.topContnt}>
                            <p className={classes.heas}>Class Attendance</p>
                            <div className={classes.btnFlex}>
                                <p>Showing result for Microbiology 100 level class attendance for MCB 103</p>
                                <button>Clear Data</button>
                            </div>
                            <br />
                            <div className={classes.tablecont}>
                                <table className={classes.myTable}>
                                    <thead className={classes.tableHead}>
                                        <tr className={classes.Ttablehead}>
                                            <th>S/N</th>
                                            <th className={classes.nameRow}>NAME OF STUDENT</th>
                                            <th>MATRIC NUMBER</th>
                                            <th>LEVEL</th>
                                            <th>DEPT</th>
                                            <th>FACULTY</th>
                                            <th>DATE</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody className={classes.theTableBody}>
                                        <tr className={classes.theTableRow}>
                                            <td>1</td>
                                            <td className={classes.nameRow}>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>2</td>
                                            <td className={classes.nameRow}>Olaide Monica Alade</td>
                                            <td>FUB45768507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>3</td>
                                            <td className={classes.nameRow}>Abubakar Mohammed Mustapha</td>
                                            <td>FUB45348507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.Nstatus}> Absent</p> </td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>4</td>
                                            <td className={classes.nameRow}>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>5</td>
                                            <td className={classes.nameRow}>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.Nstatus}>Absent</p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>6</td>
                                            <td className={classes.nameRow}>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                        <tr className={classes.theTableRow}>
                                            <td>7</td>
                                            <td className={classes.nameRow}>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>24/04/2024</td>
                                            <td><p className={classes.status}>Present </p></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div>
                                <Link to={'/view_course_scores2'}><button className={classes.createClass}>
                                    Print Class Attendance
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ViewClass_Attendance;